/* jQuery anchor link */
$(function() {
    $('a[href^="#"]').on('click', function(event) {
        var href = $(this).attr('href'),
            target = $(href == '#' || href == '' ? 'html' : href),
            position = target.offset().top;
        $('body, html').animate({ scrollTop: position }, 250, 'swing');
        event.preventDefault();
    });
});

/* Check for device type */
var detectDeviceType = function detectDeviceType() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop');
};

/* Check if element is visible */
var elementIsVisibleInViewport = function elementIsVisibleInViewport(el) {
    var partiallyVisible = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    var _el$getBoundingClient = el.getBoundingClientRect();

    var top = _el$getBoundingClient.top;
    var left = _el$getBoundingClient.left;
    var bottom = _el$getBoundingClient.bottom;
    var right = _el$getBoundingClient.right;
    var _window = window;
    var innerHeight = _window.innerHeight;
    var innerWidth = _window.innerWidth;

    return partiallyVisible ? (top > 0 && top < innerHeight || bottom > 0 && bottom < innerHeight) && (left > 0 && left < innerWidth || right > 0 && right < innerWidth) : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

/* Example of GSAP Timeline lite */
$(document).ready(function() {
    function checkPosition() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            $('#use_slider').addClass("use_slider");
            $('#service_slider').addClass("service_slider");
        }
    }
    checkPosition();

    $('.reviews_slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        arrows: false,
        responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
       
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    })
    $('.gallery_slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: false,
        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }

            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    })
    $('.features_list').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: true,
        responsive: [
        {
            breakpoint: 600,
            settings: {
               
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        }
        ]

    })
    $('.use_slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        })
    $('.service_slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
    })

    new WOW().init();
});